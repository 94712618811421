/*=================================================
=            Split - Sidebar & Content            =
=================================================*/

.layout_split {
	@include media(m) {
		display: flex;
		height: 100vh;		
	}
}

.layout_split_sidebar,
.layout_split_content {
	padding: 30px;
	@include media(m) {
		display: flex;
		flex-wrap: wrap;
		padding: 80px;
	}
}

.layout_split_sidebar {
	background-color: $brand-light;
	@include media(m) {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 40%;
	}
}

.layout_split_content {
	background: $brand-dark;
	background: $brand-dark url("/assets/images/penzance-coach-park-blue-overlay.jpg") center center no-repeat;
	background-size: cover;
	@include media(m) {
		-webkit-overflow-scrolling: touch;
		align-items: center;
		flex: 1;
		overflow-y: scroll;
		position: relative;
		width: 60%;
	}
}

.layout_split_inner {
	width: 100%;
	max-width: 100%;
}