/**
 * Utilities - Accessibility
 */

.u-assistive {
	@include assistive();
}

.u-assistive--focusable {
	@include assistive(true);
}
