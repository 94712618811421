@mixin ratio($width: 1, $height: 1) {
	position: relative;
	display: block;

	&::before {
		content: "";
		display: block;
		width: 100%;
		padding-bottom: percentage($height / $width);
	}

	> * {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
}
