// =============================================================================
// Variables
// =============================================================================

// Colors

$white: #fff;
$black: #000;
$brand-dark: #1c4c69;
$brand-light: #e93fa4;

$body-background-color: $white;
$body-color: $white;

// Typography

$base-font-size: 16;
$font-heading: 'Roboto Condensed', Sans-Serif;
$font-body: 'Cabin', Sans-Serif;

// Spacing

$base-spacing: 21;
$spacing-map: (
	1: $base-spacing,
	2: $base-spacing * 2,
	3: $base-spacing * 3,
	4: $base-spacing * 4,
	5: $base-spacing * 5,
);

// z-index

$z-index-map: (
	modal: 100,
);

// Breakpoints

$breakpoint-map: (
	s: only screen and (min-width: 20em),
	m: only screen and (min-width: 60em),
	l: only screen and (min-width: 75em),
);
