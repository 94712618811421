// =====================================
// Clearfix
// =====================================

@mixin clearfix {
	&::after {
		display: table;
		content: "";
		clear: both;
	}
}
