.button-1 {
	background: transparent;
	border: 3px solid $white;
	padding: 5px 10px;
	text-decoration: none;
	text-transform: uppercase;
	transition: all .15s;
	&:hover {
		background-color: $white;
		color: $brand-light;
	}
}